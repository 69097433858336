import { put, all, takeLatest, call, select } from "redux-saga/effects";
import environment from "../../../config/environment";
import { normalizeData } from "../../../Redux/Actions/normalizrActions";
import { paymentSchema } from "../../../Redux/Schemas/paymentSchema";
import { getConfigs, post } from "../../../services/httpService";
import {
  transactionPaymentActionFailure,
  transactionPaymentActionSuccess,
  transactionPaymentCreateFailure,
  transactionPaymentCreateSuccess,
  TRANSACTION_PAYMENT_ACTION_REQUEST,
  TRANSACTION_PAYMENT_CREATE_REQUEST,
} from "./paymentActions";
import { getSupplierSettings } from "../../../Redux/Selectors/appConfigSelector";
import { PAYMENT_METHOD_LABELS } from "../PaymentMethods";

function* handleTransactionPaymentCreate(action) {
  try {
    const {
      payload: { provider, amount, currency, transactionId, autoConfirm },
    } = action;
    const token = yield call(getConfigs);
    const supplierSettings = yield select(getSupplierSettings);
    const response = yield call(
      post,
      `${environment.api.apiBaseUrl}/api/payments`,
      {
        provider,
        amount,
        currency,
        transactionId,
      },
      token
    );
    if (response.status === 1) {
      yield put(normalizeData(response.data, paymentSchema));
      yield put(transactionPaymentCreateSuccess(response.data._id));
      if (
        provider === PAYMENT_METHOD_LABELS.wireTransfer ||
        provider === PAYMENT_METHOD_LABELS.shopCash ||
        provider === PAYMENT_METHOD_LABELS.westernUnion
      ) {
        const action = supplierSettings?.autoAcceptTransactions
          ? "accept"
          : "request";
        if (!autoConfirm) {
          yield call(handleTransactionAction, transactionId, action);
        }
      }
    } else {
      yield put(transactionPaymentCreateFailure(response.data.error));
    }
  } catch (err) {
    yield put(transactionPaymentCreateFailure(err));
  }
}

function* handleTransactionPaymentAction(action) {
  const {
    payload: { paymentId, action: paymentAction, actionParams = {} },
  } = action;
  try {
    const token = yield call(getConfigs);
    const response = yield call(
      post,
      `${environment.api.apiBaseUrl}/api/payments/${paymentId}/action`,
      {
        action: paymentAction,
        actionParams,
      },
      token
    );
    if (response.status === 1) {
      yield put(normalizeData(response.data, paymentSchema));
      yield put(transactionPaymentActionSuccess(paymentAction));
    } else {
      yield put(
        transactionPaymentActionFailure(paymentAction, response.data.error)
      );
    }
  } catch (err) {
    yield put(transactionPaymentActionFailure(paymentAction, err));
  }
}

function* handleTransactionAction(transactionId, actionType) {
  try {
    const token = yield call(getConfigs);
    yield call(
      post,
      `${environment.api.apiBaseUrl}/api/transaction/${transactionId}/action/${actionType}`,
      {},
      token
    );
  } catch (err) {
    console.log(err);
  }
}

export default function* transactionPaymentSaga() {
  yield all([
    takeLatest(
      TRANSACTION_PAYMENT_CREATE_REQUEST,
      handleTransactionPaymentCreate
    ),
    takeLatest(
      TRANSACTION_PAYMENT_ACTION_REQUEST,
      handleTransactionPaymentAction
    ),
  ]);
}
