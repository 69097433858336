import React from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import baseTheme from "../../../../config/baseTheme";

const TimeQuantityPicker = ({ onChange, values = [], value }) => {
  const { t } = useTranslation("shop");

  const handleOnChange = (val) => {
    onChange(val);
  };

  return (
    <>
      <Dropdown
        style={{ border: `1px solid ${baseTheme.borders.gray} ` }}
        onClick={(e) => e.stopPropagation()}
        value={value?.toString()}
        onChange={handleOnChange}
        options={values.filter((items) => items.flagToSuppiler)}
        bordered={false}
        placeholder={t("quantityByPeriods.numberOfHoursToReserve")}
      />
    </>
  );
};

export default TimeQuantityPicker;
