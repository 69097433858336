import { useCallback, useContext } from "react";
import Dropdown from "../../Dropdown/Dropdown";
import { CityContext } from "../Context/CityContext";
import baseTheme from "../../../config/baseTheme";
import { Spin } from "antd";
import { debounce, capitalize } from "lodash";

const CitySelector = ({
  width = "100%",
  size = "large",
  placeholder,
  showSearch = true,
  country,
  onSelect,
  ...restProps
}) => {
  const { cities = [], fetching, getCityByName } = useContext(CityContext);

  const delayedSearchQuery = useCallback(
    debounce((query) => getCityByName(query), 500),
    []
  );
  const onChange = (search) => {
    delayedSearchQuery(search);
  };

  const normalizedCities = cities
    .map((city) => ({
      value: city.codigo,
      label: capitalize(city.localidad),
    }))
    .slice(0, 10);

  return (
    <Dropdown
      showSearch
      size="large"
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onSelect}
      onSearch={onChange}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      options={normalizedCities}
      style={{
        border: `1px solid ${baseTheme.borders.gray}`,
        borderRadius: "10px",
        fontSize: 30,
      }}
      {...restProps}
    />
  );
};

export default CitySelector;
