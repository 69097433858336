export const sanitizeSubscriptionFeatures = (subscriptionsFeatures = {}) => {
  const { tools, booking } = subscriptionsFeatures;
  const defaultConfig = {
    tools: {
      transferItems: false,
    },
    booking: {
      rental: true,
      experience: false,
    },
  };
  return {
    tools: { ...defaultConfig.tools, ...tools },
    booking: { ...defaultConfig.booking, ...booking },
  };
};
