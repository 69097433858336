import { ShopOutlined } from "@ant-design/icons";
import {
  WarningPickUpTimeButton,
  WarningPickUpTimeWrapper,
  WarningWrapper,
} from "./FloatingSummary.styles";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import BootstrapIcon from "../../../../components/BootstrapIcon/BoostrapIcon";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";
import { isModoMono } from "../../../../helpers/rentals";
import { shallowEqual, useSelector } from "react-redux";
import { getBranchSelected } from "../../redux/selectors/ShopSelectors";

const SummaryWarnings = ({ dates, handleShowPickUpAndDropOffModal }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const isModoMonoActive = isModoMono(supplierData?._id);
  const { pickUpBranch, dropOffBranch } = useSelector(
    getBranchSelected,
    shallowEqual
  );

  const branchWarningComponent = (
    <WarningWrapper>
      <ShopOutlined type="warning" /> {t("shop:warning.selectBranch")}
    </WarningWrapper>
  );

  const timeWarningComponent = (
    <WarningWrapper>
      {isMobile ? (
        <WarningPickUpTimeButton
          type="link"
          onClick={handleShowPickUpAndDropOffModal}
        >
          {t("shop:warning.pickTime")}
        </WarningPickUpTimeButton>
      ) : (
        <>
          <BootstrapIcon iconName="clock" style={{ marginRight: 4 }} />
          {t("shop:warning.pickTime")}
        </>
      )}
    </WarningWrapper>
  );

  const shouldWarn = {
    time: !dates.dropoffTime && !dates.pickupTime,
    branches: isModoMonoActive && (!pickUpBranch?._id || !dropOffBranch?._id),
  };
  const warningComponents = {
    time: timeWarningComponent,
    branches: branchWarningComponent,
  };

  const warnings = Object.entries(shouldWarn)
    .filter(([_, isActive]) => isActive)
    .map(([key]) => warningComponents[key]);

  return <WarningPickUpTimeWrapper>{warnings}</WarningPickUpTimeWrapper>;
};

export default SummaryWarnings;
