import { DISCOUNT_TYPES } from "../../ShopPage/constants";

export const spPromotionExperiencesList = {
  // ADD_ PROMOTION_EXPERIENCE_LIST
  "6568e0b708b6a6f68a429666": {
    value: 40,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP40",
  },
  "6568ee6368b70b6a2d001da8": {
    value: 35,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP35",
  },
  "656e3e71adb20865b5519b26": {
    value: 38,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP38",
  },
  "656e4b0d07d1fac7caf2f3e1": {
    value: 50,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP50",
  },
  "656e5265adb208d6ed51bcb1": {
    value: 30,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP30",
  },
  "656e5a34e24de23cd47b2c5d": {
    value: 45,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP45",
  },
  "656e6e6ae24de267c47b45c9": {
    value: 20,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP20",
  },
  "6571dbf6d15121202bd887cb": {
    value: 50,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP50",
  },
  "657bb1511f3a55b542937ebf": {
    value: 50,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP50",
  },
  "657c718d53c1a07db13f0d48": {
    value: 60,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP60",
  },
  "65f248a91ec47f99a0b4a9ad": {
    value: 20,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP20",
  },
  "657cbc921f3a555b9194181b": {
    value: 60,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP60",
  },
  "657cc2d353c1a089943f5caf": {
    value: 50,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP50",
  },
  "656e56a3adb208325951c3c9": {
    value: 40,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP40",
  },
  "656e707a07d1fae39af325ff": {
    value: 45,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP45",
  },
  "659d8fe0b04e5fa3f0e49aa3": {
    value: 55,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP55",
  },
  "65a02faff2f4a112bb32df7b": {
    value: 30,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP30",
  },
  "65d6465e553d5e8641314685": {
    value: 26,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP26",
  },
  "65d6522e553d5e2f92315848": {
    value: 30,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP30",
  },
  "65e34d8f56c3ae0d892fdee0": {
    value: 30,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP30",
  },
  "65f255381ec47f04ebb4ad2e": {
    value: 20,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP20",
  },
  "65f0c303dfa29dc67710a0c6": {
    value: 25,
    discountType: DISCOUNT_TYPES.PERCENTAGE,
    discountCode: "SP25",
  },
};
