import { useEffect, useState } from "react";
import useSaleListing from "./useSaleListing";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getProductsData } from "../redux/summaryProductSelectors";
import { find, isEmpty } from "lodash";
import { paymentSummaryGetProductsDataRequest } from "../redux/summaryProductsActions";

const useProductsSummary = ({ saleData }) => {
  const dispatch = useDispatch();
  const { productQuantities, ticketsQuantity } = useSaleListing({ saleData });
  const { productsData, productsNotLoaded } = useSelector(
    (state) =>
      getProductsData(
        state,
        productQuantities.map((product) => product.productId)
      ),
    shallowEqual
  );
  const [productsSummary, setProductsSummary] = useState([]);

  const getProductData = (productId) => {
    return find(productsData, { _id: productId });
  };

  useEffect(() => {
    if (!isEmpty(productsNotLoaded)) {
      dispatch(
        paymentSummaryGetProductsDataRequest({ productsIds: productsNotLoaded })
      );
    }
  }, [productsNotLoaded]);

  useEffect(() => {
    const buildProductsSummary = [];
    productQuantities.forEach(({ productId, quantity }) => {
      const productData = getProductData(productId);
      if (!productData) {
        return;
      }
      buildProductsSummary.push({
        title: productData?.title,
        quantity,
      });
    });
    ticketsQuantity.forEach(({ rate, quantity }) =>
      buildProductsSummary.push({
        title: rate,
        quantity,
      })
    );
    if (
      JSON.stringify(buildProductsSummary) !== JSON.stringify(productsSummary)
    ) {
      setProductsSummary(buildProductsSummary);
    }
  }, [productQuantities, ticketsQuantity, productsData]);

  return { productsSummary };
};

export default useProductsSummary;
