import { compact, difference, uniq } from "lodash";
import { getProducts } from "../../../Redux/Selectors/productSelectors";

export const getProductsData = (state, summaryProductIds = []) => {
  const filteredSummaryProductIds = uniq(summaryProductIds);
  const productsData = compact(getProducts(state, filteredSummaryProductIds));
  const productIdLoaded = productsData.map((product) => product._id);
  const productsNotLoaded = difference(
    filteredSummaryProductIds,
    productIdLoaded
  );
  return {
    productsNotLoaded,
    productsData,
  };
};
