import { put, all, takeLatest, call, select, take } from "redux-saga/effects";
import { signInAnonymously, signOut } from "firebase/auth";
import { getLanguage, normalizeParams } from "../../helpers/tools";
import { signUpAuth } from "../../services/firebaseAuth";
import { get, post } from "../../services/Api";
import environment from "../../config/environment";
import {
  getTransactionByIdSuccess,
  GET_TRANSACTION_BY_ID_REQUEST,
  performTransactionFailure,
  performTransactionSuccess,
  PERFORM_TRANSACTION_REQUEST,
} from "./TransactionActions";
import { transactionSchema } from "../../Redux/Schemas/transactionSchema";
import { normalizeData } from "../../Redux/Actions/normalizrActions";
import { getCookieValue } from "../../services/cookies";
import { getConfigs } from "../../services/httpService";
import { getMainMemberInfo } from "../ShopPage/redux/selectors/MainMemberSelectors";
import {
  createNewCustomerRequest,
  CREATE_NEW_CUSTOMER_REQUEST,
  createNewCustomerSuccess,
  CREATE_NEW_CUSTOMER_SUCCESS,
  SIGN_IN_CUSTOMER_REQUEST,
  signInCustomerSuccess,
} from "../ShopPage/redux/actions/MainMemberActions";
import { isEmpty } from "lodash";
import { transactionPaymentCreateRequest } from "../PaymentPage/redux/paymentActions";
import { PAYMENT_METHOD_LABELS } from "../PaymentPage/PaymentMethods";

export const sanitizeClientIdGA = (clientId) => {
  if (!clientId) return "";
  const clientIdSplit = clientId.split(".");
  return `${clientIdSplit[2]}.${clientIdSplit[3]}`;
};

const getReferralCodeHeader = () => {
  const referralcode = getCookieValue("referralcode");
  return referralcode ? { referralcode } : {};
};

export const getClientIdGAHeader = () => {
  const clientIddGA = getCookieValue("_ga");
  const sanitizedClientIdGA = sanitizeClientIdGA(clientIddGA);
  return sanitizedClientIdGA ? { clientidga: sanitizedClientIdGA } : {};
};

function* handleNewTransaction(action) {
  const {
    listing,
    to,
    from,
    period,
    passengers,
    pickAndDrop,
    discountCode,
    currency,
    referral,
    creationSource,
    housingInfo,
    autoConfirm,
    dropoffShop,
  } = action.payload;
  try {
    const mainMember = yield select(getMainMemberInfo);

    yield call(registerMainMember, mainMember);
    yield take(CREATE_NEW_CUSTOMER_SUCCESS);
    const token = yield call(getConfigs);
    const discountParam = !isEmpty(discountCode) ? { discountCode } : {};
    const response = yield call(
      post,
      `${environment.api.apiBaseUrl}/api/v4/transactions`,
      {
        listing,
        to,
        from,
        period,
        guestCurrency: currency,
        createdThrough: "website",
        assignments: {
          ...passengers,
        },
        pickAndDrop,
        ...discountParam,
        referralId: referral,
        housingInfo,
        autoConfirm,
        dropoffShop,
      },
      {
        headers: {
          ...getReferralCodeHeader(),
          ...getClientIdGAHeader(),
          Authorization: token.headers.Authorization,
        },
      }
    );
    if (response.status === 1) {
      yield put(performTransactionSuccess(response.data));
      if (creationSource === "checkin") {
        yield put(
          transactionPaymentCreateRequest({
            provider: PAYMENT_METHOD_LABELS.shopCash,
            amount: response.data.price,
            currency,
            transactionId: response.data._id,
            autoConfirm,
          })
        );
      }
    } else {
      yield put(performTransactionFailure(response.data?.message));
    }
  } catch (err) {
    yield put(performTransactionFailure(err.message));
  }
}

function* handleGetTransactionById(action) {
  const { id, currency } = action.payload;

  try {
    const token = yield call(getConfigs);
    const filters = normalizeParams({
      _id: [id],
    });
    const response = yield call(
      get,
      `${environment.api.apiBaseUrl}/api/v2/transactions`,
      {
        ...token,
        params: {
          ...filters,
          relationships: [
            "guest",
            "itemsNotDeep",
            "host",
            "payment",
            "booking",
          ],
          currency,
        },
      }
    );

    if (response.status === 200) {
      const transaction = response.data.data.docs[0];
      if (!isEmpty(transaction)) {
        yield put(normalizeData(response.data.data.docs[0], transactionSchema));
        yield put(getTransactionByIdSuccess(id));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleSignInCustomer(action) {
  const { customerId } = action.payload;
  const response = yield call(
    post,
    `${environment.api.apiBaseUrl}/api/auth/access`,
    {
      uid: customerId,
      provider: "email",
    }
  );
  yield call(saveToken, response.data.token);
  yield put(signInCustomerSuccess());
}

function* registerMainMember(memberInfo) {
  const token = yield localStorage.getItem("userToken");
  if (token) {
    yield localStorage.removeItem("userToken");
    yield signOut(signUpAuth);
  }
  const userData = yield signInAnonymously(signUpAuth);
  if (userData) {
    const { user } = userData;
    yield put(
      createNewCustomerRequest({
        displayName: `${memberInfo.name} ${memberInfo.surname}`,
        uid: user.uid,
        provider: "anonymous",
        accountSettings: {
          defaultLang: getLanguage(),
        },
        dateOfBirth: memberInfo?.dateOfBirth ?? null,
        nationality: memberInfo.nationality,
        province: memberInfo.province || null,
        city: memberInfo.city || 0,
        customerType: memberInfo.customerType, // ADD
        email: memberInfo.email,
        address: memberInfo.address,
        phone: `${memberInfo.phonePrefix}${memberInfo.phoneNumber}`,
        ceatedBy: memberInfo.createdBy, // ADD
        personalId: {
          number: memberInfo.number,
          type: memberInfo.type,
        },
      })
    );
  }
}

function* handleCreateUser(action) {
  try {
    const userData = action.payload;
    const response = yield call(
      post,
      `${environment.api.apiBaseUrl}/api/auth/access`,
      {
        ...userData,
      }
    );
    if (response.status !== 500 && response.status !== 401) {
      yield call(saveToken, response.data.token);
      yield put(createNewCustomerSuccess(response.data));
      return response.user;
    } else {
      yield signOut(signUpAuth);
      yield put(performTransactionFailure());
    }
  } catch (err) {
    yield signOut(signUpAuth);
    yield put(performTransactionFailure(err));
  }
}

function saveToken(token) {
  localStorage.setItem("userToken", token);
}

export default function* transactionSaga() {
  yield all([
    takeLatest(PERFORM_TRANSACTION_REQUEST, handleNewTransaction),
    takeLatest(GET_TRANSACTION_BY_ID_REQUEST, handleGetTransactionById),
    takeLatest(CREATE_NEW_CUSTOMER_REQUEST, handleCreateUser),
    takeLatest(SIGN_IN_CUSTOMER_REQUEST, handleSignInCustomer),
  ]);
}
