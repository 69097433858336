export const PRICE_TYPES = Object.freeze({
  adult: "adult",
  child: "child",
  senior: "senior",
});

export const EXPERIENCE_DURATION = Object.freeze({
  PER_DAY: "perDay",
  PER_ACTIVITY: "perActivity",
});

export const BANNER_PAGE = Object.freeze({
  formScreen: "formScreen",
  checkInPageToProducts: "checkInPageToProducts",
  experienceScreen: "experienceScreen",
  homePage: "homePage",
  paymentScreen: "paymentScreen",
  productsScreen: "productsScreen",
  sizeScreen: "sizeScreen",
  passengersScreen: "passengersScreen",
  detailExperienceScreen: "detailExperienceScreen",
});
