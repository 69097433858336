import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${({ theme }) => `1px solid ${theme.borders.gray}`};
  align-items: center;
  padding: 20px 0px;
  gap: 20px;
  width: 100%;
`;

export const EmptyProductsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 40px 0px;
`;
