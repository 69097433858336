import { useEffect, useState } from "react";
import { countBy, isEmpty } from "lodash";
import { calculateRateQuantities } from "../utils";
import { SALE_TYPES, PRODUCT_TYPE } from "../../ShopPage/constants";
import { TRANSACTION_TYPE } from "../../HomePage/helpers/constants";

const useSaleListing = ({ saleData }) => {
  const [saleType, setSaleType] = useState(null);
  const [productQuantities, setProductQuantities] = useState([]);
  const [ticketsQuantity, setTicketsQuantities] = useState([]);

  useEffect(() => {
    if (isEmpty(saleData)) {
      return;
    }
    if (!isEmpty(saleData.creationData)) {
      setSaleType(SALE_TYPES.presale);
    } else {
      setSaleType(SALE_TYPES.transaction);
    }
  }, [saleData]);

  const parsePesaleProducts = (preSale) => {
    const listing = preSale?.creationData?.listing ?? [];
    const products = listing.filter(
      (item) => item.type === PRODUCT_TYPE.PRODUCT
    );
    const productsQuantity = countBy(products, "id");
    const parsedProductsQuantity = Object.entries(productsQuantity).map(
      ([productId, quantity]) => ({ productId, quantity })
    );
    setProductQuantities(parsedProductsQuantity);
  };

  const parseTransactionProducts = (transaction) => {
    if (
      transaction.bookingType === TRANSACTION_TYPE.RENTAL ||
      isEmpty(transaction.bookingType)
    ) {
      const productsQuantity = countBy(transaction.items, "product");
      const parsedProductsQuantity = Object.entries(productsQuantity).map(
        ([productId, quantity]) => ({ productId, quantity })
      );
      setProductQuantities(parsedProductsQuantity);
    } else {
      const assignments = transaction.assignments ?? [];
      const ticketsQuantity = assignments.map((passenger) => ({
        rate: passenger?.rate,
        quantity: calculateRateQuantities(assignments, passenger?.rate),
      }));
      setTicketsQuantities(ticketsQuantity);
    }
  };

  useEffect(() => {
    if (!saleType) {
      return;
    }
    if (saleType === SALE_TYPES.presale) {
      parsePesaleProducts(saleData);
    } else if (saleType === SALE_TYPES.transaction) {
      parseTransactionProducts(saleData);
    }
  }, [saleType]);

  return { productQuantities, ticketsQuantity };
};

export default useSaleListing;
