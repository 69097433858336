import { isEmpty, union } from "lodash";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import environment from "../../../../config/environment";
import { normalizeParams } from "../../../../helpers/tools";
import { normalizeData } from "../../../../Redux/Actions/normalizrActions";
import { productsSchema } from "../../../../Redux/Schemas/productSchema";
import { bundlesSchema } from "../../../../Redux/Schemas/bundlesSchema";
import { get } from "../../../../services/Api";
import {
  getActivitiesSuccess,
  getPricingSuccess,
  getProducsShopSuccess,
  GET_PRICING_REQUEST,
  getBundlesShopSuccess,
  GET_ALL_PRODUCTS_SHOP_REQUEST,
} from "../actions/shopActions";
import { listTypes, normalizeListingParam } from "../../utils";
import { getSupplierData } from "../../../../Redux/Selectors/appConfigSelector";

function* handleGetAllProducts(action) {
  try {
    const { params, currency } = action.payload;
    const { visibleOnWeb, ...restParams } = params;

    const normalizedParams = normalizeParams({ ...params });
    const normalizedBundleParams = normalizeParams({ ...restParams });

    const [productsResponse, bundlesResponse] = yield all([
      call(get, `${environment.api.apiBaseUrl}/api/v2/products`, {
        params: {
          pagination: false,
          relationships: ["category", "owner"],
          ...normalizedParams,
          currency,
        },
      }),
      call(get, `${environment.api.apiBaseUrl}/api/bundles`, {
        params: {
          pagination: false,
          relationships: ["owner", "products", "category"],
          ...normalizedBundleParams,
          currency,
        },
      }),
    ]);
    if (productsResponse.status === 200) {
      yield put(normalizeData(productsResponse.data.data.docs, productsSchema));
      yield put(
        getProducsShopSuccess(
          productsResponse.data.data.docs.map((doc) => doc._id)
        )
      );
      const mappedActivities = productsResponse.data.data.docs.map(
        (product) => product.activity
      );
      const activities = union(...mappedActivities);
      yield put(getActivitiesSuccess(activities));
    }
    if (bundlesResponse.status === 200) {
      yield put(normalizeData(bundlesResponse.data.data.docs, bundlesSchema));
      yield put(
        getBundlesShopSuccess(
          bundlesResponse.data.data.docs.map((doc) => doc._id)
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
}
function* handleGetPricing(action) {
  const {
    from,
    to,
    period,
    items,
    customPricing = {},
    currency,
    allowDiscounts,
    type,
    discountCode,
  } = action.payload;

  const normalizedItems =
    type === listTypes.PRODUCT
      ? normalizeParams(items, "listing")
      : normalizeListingParam(items, "listing");

  const normalizedCustomPricing = normalizeParams(
    customPricing,
    "customPricing"
  );

  try {
    const endpoint = `${environment.api.apiBaseUrl}/api/v3/pricing/transaction`;
    const supplierData = yield select(getSupplierData);
    const discountParam = !isEmpty(discountCode) ? { discountCode } : {};
    const [response, responseOriginalCurrency] = yield all([
      call(get, endpoint, {
        params: {
          ...normalizedItems,
          ...normalizedCustomPricing,
          from,
          to,
          period,
          currency,
          allowDiscounts,
          supplier: supplierData._id,
          ...discountParam,
        },
      }),
      call(get, endpoint, {
        params: {
          ...normalizedItems,
          ...normalizedCustomPricing,
          from,
          to,
          period,
          allowDiscounts,
          supplier: supplierData._id,
          ...discountParam,
        },
      }),
    ]);
    yield put(
      getPricingSuccess({
        ...response.data.data,
        hostCurrency: responseOriginalCurrency.data.data.currency,
        hostTotal: responseOriginalCurrency.data.data.total,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

export default function* quoteSupplierSaga() {
  yield all([
    takeLatest(GET_PRICING_REQUEST, handleGetPricing),
    takeLatest(GET_ALL_PRODUCTS_SHOP_REQUEST, handleGetAllProducts),
  ]);
}
