import React from "react";
import PropTypes from "prop-types";
import useWindowSize from "../../../../../hooks/useWindowSize";
import FloatContainer from "../../../../../components/FloatContainer/FloatContainer";
import { CallToActionButton, Container, Line } from "./Sider.styles";
import RentalInfo from "../RentalInfo/RentalInfo";
import DateAndTimePickerContainer from "../../containers/DateAndTimePickerContainer/DateAndTimePickerContainer";
import { Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addExperienceToSummary } from "../../../../ShopPage/redux/actions/shopActions";
import AddParticipantContainer from "../../containers/AddParticipantsContainer";
import AddParticipants from "../AddParticipant/AddParticipants";
import Price from "../../../../../components/Price/Price";
import { first, isEmpty } from "lodash";
import Paragraph from "antd/es/typography/Paragraph";
import { WarningOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import BannerRenderContainer from "../../../../../components/BannerComponent/BannerRenderContainer";
import DiscountInput from "../../../../ShopPage/components/DiscountInput/DiscountInput";
import useDiscounts from "../../../../ShopPage/containers/PricingContainer/hooks/useDiscounts";
import { BANNER_PAGE } from "../../../list/helpers/constants";

const isBookingEnabled = true;

const Sider = ({
  experienceId,
  durationExperience,
  onClickInCTA,
  ctaData,
  supplierId,
}) => {
  const { t } = useTranslation(["experiences"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { height } = useWindowSize();
  const { handleApplyDiscount, handleRemoveDiscount, discountCode } =
    useDiscounts();

  const onContinue = () => {
    dispatch(addExperienceToSummary(experienceId));
    navigate(`/experiences/${experienceId}/passengers`);
  };

  return (
    <>
      <FloatContainer height={height}>
        <Container>
          <Title
            level={5}
            style={{ padding: 0, margin: 0, textAlign: "center" }}
          >
            {t("booking.selectDate")}
          </Title>
          <Line />
          {!isBookingEnabled && (
            <CallToActionButton onClick={onClickInCTA} data={ctaData} />
          )}
          {isBookingEnabled && (
            <AddParticipantContainer
              experienceId={experienceId}
              period={durationExperience.unit}
              supplierId={supplierId}
            >
              {({
                participantOptions,
                handleOnAddParticipant,
                handleOnRemoveParticipant,
                tickets,
                ageSettings,
                pricingData,
                isPricingFetching,
                validationMessage,
                isSelectedDate,
                setIsSelectedDate,
              }) => {
                const discountsProps = {
                  onApplyDiscount: handleApplyDiscount,
                  onRemoveDiscount: handleRemoveDiscount,
                  discountError:
                    discountCode && isEmpty(pricingData?.discounts),
                };
                const getDiscount = () => {
                  if (
                    isEmpty(pricingData?.discounts) ||
                    isEmpty(pricingData?.discounts[0]?.code)
                  ) {
                    return null;
                  }
                  const discountData = pricingData.discounts[0];
                  return {
                    code: discountData.code,
                    amountDiscount: `${discountData.aliquotDiscounted}%`,
                  };
                };
                return (
                  <>
                    <DateAndTimePickerContainer
                      experienceId={experienceId}
                      durationExperience={durationExperience}
                      isSelectedDate={isSelectedDate}
                      onSelectDate={setIsSelectedDate}
                    />{" "}
                    <BannerRenderContainer
                      screen={BANNER_PAGE.detailExperienceScreen}
                    />
                    <AddParticipants
                      participantOptions={participantOptions}
                      handleOnAddTicket={handleOnAddParticipant}
                      handleOnRemoveTicket={handleOnRemoveParticipant}
                      tickets={tickets}
                      ageSettings={ageSettings}
                    />
                    <Line />
                    {!isEmpty(discountsProps) && !isPricingFetching && (
                      <Row>
                        <DiscountInput
                          discount={getDiscount()}
                          {...discountsProps}
                        />
                      </Row>
                    )}
                    {!isEmpty(pricingData) && (
                      <Price
                        showTotal={true}
                        currencySymbol={pricingData?.currency?.symbol}
                        currency={pricingData?.currency?.currency}
                        price={pricingData?.total}
                        isTotal
                        isPricingFetching={isPricingFetching}
                        discounts={pricingData?.discounts}
                        rentalPeriodType={pricingData?.period}
                      />
                    )}
                    <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                      <Button
                        type="primary"
                        shape="round"
                        block
                        size="large"
                        disabled={!isEmpty(validationMessage)}
                        onClick={onContinue}
                      >
                        {t("continue")}
                      </Button>
                    </div>
                    {!isEmpty(validationMessage) && (
                      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                        <Paragraph type="warning" style={{ padding: 0 }}>
                          <WarningOutlined /> {first(validationMessage)}
                        </Paragraph>
                      </div>
                    )}
                  </>
                );
              }}
            </AddParticipantContainer>
          )}
        </Container>
      </FloatContainer>
    </>
  );
};

Sider.propTypes = {
  rentalInfo: PropTypes.shape(RentalInfo.propTypes),
  experienceId: PropTypes.string,
  durationExperience: PropTypes.object,
};

export default Sider;
