import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LayoutWrapper from "../components/LayoutWrapper/LayoutWrapper";
import PassengerBuilder from "../components/PassengerBuilder/PassengerBuilder";
import ShopWrapper from "../components/ShopWrapper/ShopWrapper";
import PassengersContainer from "../containers/PassengersContainer/PassengersContainer";
import PricingContainer from "../containers/PricingContainer/PricingContainer";
import ScrollToTop from "../../../hooks/useScrollToTop";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../../services/mixpanel";
import { useSelector } from "react-redux";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import BannerRenderContainer from "../../../components/BannerComponent/BannerRenderContainer";
import { BANNER_PAGE } from "../../ExperiencesPage/list/helpers/constants";

const PassengersScreen = ({ isIframe }) => {
  const { t } = useTranslation("shop");
  const supplierData = useSelector(getSupplierData);

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "PassengersPage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  return (
    <LayoutWrapper isIframe={isIframe}>
      <BannerRenderContainer screen={BANNER_PAGE.passengersScreen} />
      <PricingContainer>
        {({
          pricingData,
          isPricingFetching,
          discount: {
            handleApplyDiscount,
            handleRemoveDiscount,
            discountError,
          },
        }) => {
          const discountsProps = {
            onApplyDiscount: handleApplyDiscount,
            onRemoveDiscount: handleRemoveDiscount,
            discountError,
          };
          return (
            <PassengersContainer>
              {({
                quoteSummary,
                addPeopleForm,
                handleOnFinish,
                handleOnRemovePassenger,
                passengersData,
                handleOnAddPeopleContinue,
                itemsLength,
                handleOnBlur,
                handleOnFocus,
                footerIsVisible,
                supplierData,
                selectedProducts,
                searchValues,
              }) => {
                return (
                  <ShopWrapper
                    itemsLength={itemsLength}
                    pricingData={pricingData}
                    quoteSummary={quoteSummary}
                    selectedProducts={selectedProducts}
                    isPricingFetching={isPricingFetching}
                    handleOnSummaryClick={handleOnAddPeopleContinue}
                    buttonLabel={t("shop:assignEquipment")}
                  >
                    <ScrollToTop />
                    <PassengerBuilder
                      quoteSummary={quoteSummary}
                      formController={addPeopleForm}
                      handleOnFinish={handleOnFinish}
                      handleOnRemovePassenger={handleOnRemovePassenger}
                      initialFormData={Object.values(passengersData)}
                      handleOnBlur={handleOnBlur}
                      handleOnFocus={handleOnFocus}
                      supplierData={supplierData}
                      handleOnClick={handleOnAddPeopleContinue}
                      pricingData={pricingData}
                      isPricingFetching={isPricingFetching}
                      itemsLength={itemsLength}
                      footerIsVisible={footerIsVisible}
                      selectedProducts={selectedProducts}
                      searchValues={searchValues}
                      discounts={discountsProps}
                    />
                  </ShopWrapper>
                );
              }}
            </PassengersContainer>
          );
        }}
      </PricingContainer>
    </LayoutWrapper>
  );
};

export default PassengersScreen;
