import EmptyPlaceholder from "../EmptyPlaceholder/EmptyPlaceholder";
import ProductsSummary from "../ProductsSummary/ProductsSummary";
import SummaryWarnings from "./SummaryWarnings";

const ProductsSection = ({
  itemsLength,
  dates,
  pricingData,
  expandedDefault,
  handleShowPickUpAndDropOffModal,
}) => {
  if (itemsLength > 0 && dates.dropoffTime && dates.pickupTime) {
    return (
      <ProductsSummary
        products={pricingData?.items}
        expanded={expandedDefault}
      />
    );
  }

  if (itemsLength > 0) {
    return (
      <SummaryWarnings
        dates={dates}
        handleShowPickUpAndDropOffModal={handleShowPickUpAndDropOffModal}
      />
    );
  }
  if (itemsLength === 0 && expandedDefault) {
    return <EmptyPlaceholder />;
  }
  return null;
};

export default ProductsSection;
