import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { getSupplierPlan } from "../features/Subscription/redux/selectorSubscription";

const MENU_LINKS = [
  {
    label: "aboutUs",
    path: "/",
    anchor: "about-us",
    external: false,
    blank: false,
  },
  {
    label: "contact",
    path: "/",
    anchor: "contact-us",
    external: false,
    blank: false,
  },
  {
    label: "experiences",
    path: "/experiences",
    anchor: false,
    external: false,
    blank: false,
  },
];

const HEADER_OFFSET = 70;

const useMenuLinks = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const subscription = useSelector((state) => getSupplierPlan(state));
  const optionalLinks = {
    experiences: !subscription.booking.experience,
  };

  useEffect(() => {
    if (location.hash) {
      const hash = location.hash.replace(/^#/, "");
      scroller.scrollTo(hash, {
        offset: -HEADER_OFFSET,
      });
    }
  }, []);

  const navigateToDifferentPath = ({ external, path, anchor, blank }) => {
    if (!external) {
      const hash = anchor ? `#${anchor}` : "";
      navigate(`${path}${location.search}${hash}`);
    } else {
      window.open(path, blank ? "_blank" : "_self");
    }
  };

  const handleNavigate = (e, linkIndex) => {
    e.preventDefault();
    const link = MENU_LINKS[linkIndex];
    if (!link) {
      return;
    }
    if (location.pathname !== link.path || link.external) {
      navigateToDifferentPath(link);
      return;
    }
    if (link.anchor) {
      scroller.scrollTo(link.anchor, {
        smooth: true,
        offset: -HEADER_OFFSET,
      });
    }
  };

  return {
    links: MENU_LINKS.filter((link) => !optionalLinks[link.label]),
    handleNavigate,
  };
};

export default useMenuLinks;
