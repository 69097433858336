import React, { useEffect } from "react";
import styled from "styled-components";
import Layout from "antd/es/layout";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import usePaymentExchange from "../../hooks/usePaymentExchange";
import useTransactionPayment from "../../hooks/useTransactionPayment";
import OverlaySpiner from "../../components/OverlaySpinner/OverlaySpinner";
import RemainingPayment from "./components/RemainingPayment";
import PaymentMethodSelection from "./components/PaymentMethodSelection";
import PaymentMethodForm from "./components/Form/PaymentMethodForm";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import queryString from "query-string";
import Navbar from "../../components/Navbar/Navbar";
import { getImageUrl } from "../../helpers/cloudinary";
import { shallowEqual, useSelector } from "react-redux";
import { getSupplierData } from "../../Redux/Selectors/appConfigSelector";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../services/mixpanel";
import { renderPrice } from "../../helpers/tools";
import BannerRenderContainer from "../../components/BannerComponent/BannerRenderContainer";
import { BANNER_PAGE } from "../ExperiencesPage/list/helpers/constants";
import useProductsSummary from "./hooks/useProductsSummary";

const StyledLayout = styled(Layout)`
  padding-top: 10px;
  min-height: 100vh;
  background: #fff;
`;

const TransactionPaymentScreen = ({ isIframe }) => {
  const { t } = useTranslation("result");
  const navigate = useNavigate();
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const { id } = useParams();
  const { search } = useLocation();
  const {
    paymentMethod: {
      paymentMethodIdentifier,
      setPaymentMethodId,
      paymentMethodData,
    },
    transaction: { loadingTransaction, transaction },
    paymentData: {
      percentage,
      amountPaid,
      paymentTotal,
      paymentCompleted,
      subtotal,
      discount,
    },
  } = useTransactionPayment({
    transactionId: id,
    supplierId: supplierData?._id,
    customerId: queryString.parse(search).customerId,
  });

  const { productsSummary } = useProductsSummary({ saleData: transaction });

  const typeValue = transaction?.bookingType ?? transaction?.type;

  const {
    amount: convertedAmount,
    currency: convertedCurrency,
    loading: loadingExchange,
    subtotal: convertedSubtotal,
    discount: convertedDiscount,
  } = usePaymentExchange({
    paymentAmount: paymentTotal,
    subtotalAmount: subtotal,
    discountAmount: discount,
    paymentCurrency: transaction?.hostCurrency?.currency,
    guestCurrency: transaction?.guestCurrency?.currency || null,
  });

  const buildSummary = () => {
    const commonProps = {
      bookingType: typeValue,
      products: productsSummary,
      percentage: percentage < 100 ? percentage : null,
      amountPaid,
    };

    if (isSameCurrency) {
      return {
        ...commonProps,
        total: transaction.hostPrice,
        currencySymbol: transaction?.hostCurrency?.symbol,
        currency: transaction?.hostCurrency?.currency,
        paymentAmount: paymentTotal,
        subtotal,
        discount,
      };
    } else {
      return {
        ...commonProps,
        total: convertedAmount,
        currencySymbol: transaction?.guestCurrency?.symbol,
        currency: transaction?.guestCurrency?.currency,
        paymentAmount: convertedAmount,
        subtotal: convertedSubtotal,
        discount: convertedDiscount,
      };
    }
  };

  useEffect(() => {
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "PaymentPage",
      supplier: supplierData.name,
      supplierId: supplierData._id,
    });
  }, []);

  const handleContinue = () => {
    navigate(`final${window.location.search}`);
  };

  if (loadingTransaction || loadingExchange || isEmpty(transaction)) {
    return <OverlaySpiner isLoading={true} />;
  }

  const balance = renderPrice(transaction?.balance);
  const renderRemainingPayment = () => {
    return (
      <RemainingPayment
        title={
          paymentCompleted && balance === 0
            ? t("paymentCompletedTitle")
            : t("paymentPartialTitle")
        }
        description={
          paymentCompleted && balance === 0
            ? t("paymentCompletedFullyDescription")
            : t("paymentCompletedPartialDescription", { balance })
        }
        buttonLabel={t("common:continue")}
        onClick={() => navigate(`/`)}
      />
    );
  };

  const transactionCurrency = transaction?.hostCurrency?.currency;
  const isSameCurrency =
    transactionCurrency && convertedCurrency
      ? transactionCurrency.toLowerCase() === convertedCurrency.toLowerCase()
      : true;
  const summary = buildSummary();

  return (
    <StyledLayout>
      {!isIframe && (
        <Navbar
          logo={getImageUrl(supplierData?.logo, { height: 150 })}
          menuItems={null}
          renderButtons={false}
        />
      )}
      <Layout.Content>
        <Row justify="center" style={{ marginBottom: 100, marginTop: 50 }}>
          <Col xs={22} xl={10}>
            <BannerRenderContainer screen={BANNER_PAGE.paymentScreen} />
            {paymentCompleted && renderRemainingPayment()}
            {!paymentCompleted && (
              <Routes>
                <Route
                  element={
                    <PaymentMethodSelection
                      transactionCurrency={transactionCurrency}
                      guestCurrency={
                        transaction?.guestCurrency?.currency || null
                      }
                      selectedPaymentMethodIdentifier={paymentMethodIdentifier}
                      onSelectPaymentMethodIdentifier={setPaymentMethodId}
                      onContinue={handleContinue}
                      transactionCustomId={transaction.customId}
                      summary={summary}
                      currentLink={search}
                      transactionStatus={transaction.status}
                    />
                  }
                  path="/"
                />
                <Route
                  element={
                    <PaymentMethodForm
                      transactionCustomId={transaction.customId}
                      amount={convertedAmount}
                      currency={convertedCurrency}
                      transactionId={id}
                      paymentMethodIdentifier={paymentMethodIdentifier}
                      paymentMethodData={paymentMethodData}
                      summary={summary}
                      transactionStatus={transaction.status}
                    />
                  }
                  path="final"
                />
              </Routes>
            )}
          </Col>
        </Row>
      </Layout.Content>
    </StyledLayout>
  );
};

export default TransactionPaymentScreen;
