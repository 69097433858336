import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getSupplierData,
  getWebsiteContentConfig,
} from "../../../../../Redux/Selectors/appConfigSelector";
import { getExperienceDetail } from "../../../redux/selectors/ExperiencesSelector";
import {
  getAssigmentsData,
  getPassengersData,
  getPricingData,
  getPricingIsFetching,
  getSummary,
  getTicketsQuantity,
} from "../../../../ShopPage/redux/selectors/ShopSelectors";
import { useState } from "react";
import { getTransactionStatus } from "../../../../ShopPage/redux/selectors/TransactionStatusSelectors";
import { listTypes, sanitizeVariantListing } from "../../../../ShopPage/utils";
import { toISOFormatString } from "../../../../../helpers/tools";
import { performTransactionRequest } from "../../../../TransactionPage/TransactionActions";
import {
  getExperiencePromotion,
  normalizeExperiencePassengers,
} from "../../../list/helpers/helpers";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RENTALS, isRental } from "../../../../../helpers/rentals";
import { spPromotionExperiencesList } from "../../../config/spAventuasConfig";
import useWebsiteRegion from "../../../../../hooks/useWebsiteCountry";
import useDiscounts from "../../../../ShopPage/containers/PricingContainer/hooks/useDiscounts";
import { getPricingRequest } from "../../../../ShopPage/redux/actions/shopActions";
import useWebsiteCurrency from "../../../../../hooks/useWebsiteCurrency";
import { isEmpty } from "lodash";

const ParticipantContainer = ({ children, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validationMessage, setValidationMessage] = useState([]);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const content = useSelector(getWebsiteContentConfig, shallowEqual);
  const experience = useSelector((state) => getExperienceDetail(state, id));
  const pricingData = useSelector(getPricingData);
  const isPricingFetching = useSelector(getPricingIsFetching);
  const transactionStatus = useSelector(getTransactionStatus);
  const { tickets, dates, startTimeId } = useSelector(getSummary);
  const { region } = useWebsiteRegion();
  const { currency } = useWebsiteCurrency();
  const assignmentsData = useSelector(getAssigmentsData);
  const passengerData = useSelector(getPassengersData);
  const ticketsQuantity = useSelector((state) => getTicketsQuantity(state));
  const supplierData = useSelector(getSupplierData, shallowEqual);
  const spDiscount =
    isRental(supplierData._id, RENTALS.spAventuras) &&
    region === "argentina" &&
    getExperiencePromotion(experience._id, spPromotionExperiencesList);
  const { discountCode } = useDiscounts();
  useEffect(() => {
    if (transactionStatus.status === "PREAUTHORIZED") {
      navigate(
        `/transaction/${transactionStatus._id}/pay?customerId=${transactionStatus.data.guest}`,
        { replace: true }
      );
    }
  }, [transactionStatus]);

  const generateValidation = (validationFn, warningMessage) =>
    validationFn() ? null : warningMessage;

  useEffect(() => {
    const dateValidation = generateValidation(
      checkPassengersData,
      "warning.passengerDataIsRequired"
    );
    const validations = [dateValidation].filter(Boolean);
    setValidationMessage(validations);
  }, [assignmentsData]);

  const checkPassengersData = () => {
    return ticketsQuantity === Object.keys(assignmentsData)?.length;
  };
  useEffect(() => {
    if (!isEmpty(tickets)) {
      const sanitizedVariants = Object.entries(tickets).map(([rate]) => {
        return {
          id: id,
          rate,
          type: listTypes.EXPERIENCE,
          quantity: tickets[rate],
        };
      });
      pricingRequest(sanitizedVariants);
    }
  }, [JSON.stringify(discountCode), tickets]);

  const pricingRequest = (sanitizedVariants) => {
    const allowDiscounts = true;
    dispatch(
      getPricingRequest(
        toISOFormatString(dates.from),
        toISOFormatString(dates.to),
        experience?.duration.unit,
        sanitizedVariants,
        {},
        currency,
        allowDiscounts,
        listTypes.EXPERIENCE,
        spDiscount?.discountCode ?? discountCode
      )
    );
  };
  const handleOnRequestPayment = () => {
    const sanitizedVariants = sanitizeVariantListing(
      assignmentsData,
      listTypes.EXPERIENCE,
      experience._id,
      startTimeId
    );

    const pickAndDrop = {
      pickup: {
        date: toISOFormatString(dates.from),
      },
      dropoff: {
        date: toISOFormatString(dates.to),
      },
    };

    dispatch(
      performTransactionRequest(
        sanitizedVariants,
        toISOFormatString(dates.from),
        toISOFormatString(dates.to),
        experience.duration.unit,
        experience.convertedCurrency,
        { passengers: normalizeExperiencePassengers(passengerData) },
        pickAndDrop,
        spDiscount?.discountCode ?? discountCode
      )
    );
  };

  const personalInfo = passengerData.map((data) => {
    const { name, surname, label, type, number } = data;
    return {
      label,
      properties: [
        { label: "name", value: `${name} ${surname}` },
        { label: type, value: number },
      ],
    };
  });

  const handleOnContinue = () => {
    setShowSummaryModal(true);
  };

  return children({
    content,
    experienceData: experience,
    pricingData,
    isFetching: isPricingFetching,
    dates,
    showSummaryModal,
    setShowSummaryModal,
    isBookingFetching: transactionStatus.fetching,
    handleOnContinue,
    handleOnRequestPayment,
    passengers: [{ personalInfo, title: experience?.title }],
    validationMessage,
    deleteText: spDiscount?.discountCode ? true : false,
  });
};

export default ParticipantContainer;
