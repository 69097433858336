import React from "react";
import DatePicker from "react-datepicker";
import {
  Container,
  FloatContainer,
  Footer,
  QuantityWrapper,
} from "./FloatingFooter.styles";
import Title from "antd/es/typography/Title";
import { useTranslation } from "react-i18next";
import { Line } from "../../../../ExperiencesPage/detail/components/FloatingFooter/Sider.styles";
import { Button } from "antd";
import { first, isEmpty } from "lodash";
import AddParticipantContainer from "../../containers/AddParticipantContainer";
import Paragraph from "antd/es/typography/Paragraph";
import { WarningOutlined } from "@ant-design/icons";
import ProductAddCounter from "../../../../ShopPage/components/ProductAddCounter/ProductAddCounter";
import { listTypes } from "../../../../ShopPage/utils";
import Price from "../../../../../components/Price/Price";
import {
  filterDisabledDates,
  getCalendarLanguage,
} from "../../../../../components/AvailabilityCalendar/helpers";
import { getLanguage } from "../../../../../helpers/tools";
import { CustomCalendar } from "../../../../../components/AvailabilityCalendar/AvailabilityCalendar.styles";
import BannerRenderContainer from "../../../../../components/BannerComponent/BannerRenderContainer";
import { createUTCDate } from "../../../../../helpers/dates";
import { BANNER_PAGE } from "../../../../ExperiencesPage/list/helpers/constants";

const FloatingFooter = ({ spaceId, onContinue, variants }) => {
  const { t } = useTranslation(["experiences"]);

  return (
    <FloatContainer>
      <Container>
        <Title level={5} style={{ padding: 0, margin: 0, textAlign: "center" }}>
          {t("booking.selectDate")}
        </Title>
        <Line />
        <AddParticipantContainer spaceId={spaceId} variants={variants}>
          {({
            pricingData,
            isPricingFetching,
            validationMessage,
            onDateChange,
            handleMonthChange,
            startDate,
            endDate,
            quoteSummary,
            daysLength,
            disableDates,
            maxItems,
          }) => (
            <>
              <DatePicker
                onMonthChange={handleMonthChange}
                selected={startDate}
                onChange={onDateChange}
                startDate={startDate}
                endDate={endDate}
                inline
                selectsRange
                minDate={createUTCDate().toDate()}
                locale={getCalendarLanguage(getLanguage())}
                dateFormat="dd/MM/yyyy"
                calendarContainer={CustomCalendar}
                excludeDates={filterDisabledDates(disableDates)}
              />
              <BannerRenderContainer
                screen={BANNER_PAGE.detailExperienceScreen}
              />
              <Line />
              <Footer>
                <QuantityWrapper>
                  <span>Cantidad de autos:</span>
                  <ProductAddCounter
                    itemId={spaceId}
                    quantity={quoteSummary.products[spaceId] || 0}
                    showCounter
                    disabledAdd={
                      !daysLength || quoteSummary.products[spaceId] === maxItems
                    }
                    type={listTypes.PRODUCT}
                  />
                </QuantityWrapper>

                {!isEmpty(quoteSummary.products) && (
                  <Price
                    showTotal={true}
                    currencySymbol={pricingData?.currency?.symbol}
                    currency={pricingData?.currency?.currency}
                    price={pricingData?.total}
                    isTotal
                    isPricingFetching={isPricingFetching}
                    discounts={pricingData?.discounts}
                    rentalPeriodType={pricingData?.period}
                  />
                )}

                <Button
                  type="primary"
                  shape="round"
                  block
                  size="large"
                  disabled={!isEmpty(validationMessage)}
                  onClick={onContinue}
                >
                  {t("continue")}
                </Button>
              </Footer>
              {!isEmpty(validationMessage) && (
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <Paragraph type="warning" style={{ padding: 0 }}>
                    <WarningOutlined /> {first(validationMessage)}
                  </Paragraph>
                </div>
              )}
            </>
          )}
        </AddParticipantContainer>
      </Container>
    </FloatContainer>
  );
};

export default FloatingFooter;
