import { put, all, takeLatest } from "redux-saga/effects";
import environment from "../../../config/environment";
import { normalizeData } from "../../../Redux/Actions/normalizrActions";
import { get } from "../../../services/httpService";
import { PAYMENT_SUMMARY_GET_PRODUCTS_DATA_REQUEST } from "./summaryProductsActions";
import { normalizeParams } from "../../../helpers/tools";
import { productsSchema } from "../../../Redux/Schemas/productSchema";

function* handleGetSummaryProducts(action) {
  try {
    const { productsIds } = action.payload;
    const normalizedParams = normalizeParams({
      _id: productsIds,
    });
    const response = yield get(
      `${environment.api.apiBaseUrl}/api/v2/products`,
      {
        params: {
          ...normalizedParams,
        },
      }
    );
    if (response.status === 1) {
      yield put(normalizeData(response.data.docs, productsSchema));
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* summaryProductsSaga() {
  yield all([
    takeLatest(
      PAYMENT_SUMMARY_GET_PRODUCTS_DATA_REQUEST,
      handleGetSummaryProducts
    ),
  ]);
}
