import React, { useContext } from "react";
import { Select } from "antd";
import { generateYearsArray, generateMonths } from "../../tools/dateTools";
import { useTranslation } from "react-i18next";
import Dropdown from "../Dropdown/Dropdown";
import moment from "moment";
import { DateOfBirthContainer } from "./DateOfBirthSelector.styles";
import useIsMobile from "../../hooks/useIsMobile";
import { DateOfBirthContext } from "./context/DateOfBirthContext";
import baseTheme from "../../config/baseTheme";
const { Option } = Select;

const DateOfBirthSelector = () => {
    const { t } = useTranslation("shop");
    const isMobile = useIsMobile();
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const months = generateMonths();
    const years = generateYearsArray(moment().year() - 14, 90);
    const { setDateOfBirth } = useContext(DateOfBirthContext);

    const handleDateChange = (value, key) => {
        setDateOfBirth(value, key);
    };

    return (
        <DateOfBirthContainer $isMobile={isMobile}>
            <Dropdown
                placeholder={t("dayUpperCase")}
                showSearch
                onChange={(value) => handleDateChange(value, "day")}
                size="large"
                style={{ borderRadius: "10px", borderColor: baseTheme.borders.gray }}
            >
                {days.map((day) => (
                    <Option key={day} value={day}>
                        {day}
                    </Option>
                ))}
            </Dropdown>

            <Dropdown
                placeholder={t("monthUperCase")}
                showSearch
                onChange={(_, { key }) => handleDateChange(key, "month")}
                style={{ borderRadius: "10px", borderColor: baseTheme.borders.gray }}
            >
                {months.map((month, index) => (
                    <Option key={index + 1} value={t(`common:${month.toLowerCase()}`)}>
                        {t(`common:${month.toLowerCase()}`)}
                    </Option>
                ))}
            </Dropdown>

            <Dropdown
                placeholder={t("yearUpperCase")}
                showSearch
                onChange={(value) => handleDateChange(value, "year")}
                style={{ borderRadius: "10px", borderColor: baseTheme.borders.gray }}
            >
                {years.map((year) => (
                    <Option key={year} value={year}>
                        {year}
                    </Option>
                ))}
            </Dropdown>
        </DateOfBirthContainer>
    );
};

export default DateOfBirthSelector;