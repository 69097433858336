export const PAYMENT_SUMMARY_GET_PRODUCTS_DATA_REQUEST =
  "PAYMENT_SUMMARY_GET_PRODUCTS_DATA_REQUEST";

export const paymentSummaryGetProductsDataRequest = ({ productsIds = [] }) => {
  return {
    type: PAYMENT_SUMMARY_GET_PRODUCTS_DATA_REQUEST,
    payload: {
      productsIds,
    },
  };
};
