export const PRODUCT_TYPE = Object.freeze({
  EXPERIENCE: "experience",
  PRODUCT: "product",
});
export const ITEM_TYPE = Object.freeze({
  BUNDLE: "bundle",
  VARIANT: "variant",
});

export const DISCOUNT_TYPES = Object.freeze({
  PERCENTAGE: "percentage",
  AMOUNT_FIXED: "amountFixed",
});

export const COMBO = "combo";

export const SALE_TYPES = {
  presale: "presale",
  transaction: "transaction",
};
