import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  clickEvents,
  registerSuperProperties,
  trackEvent,
} from "../../../services/mixpanel";
import LayoutWithDataContainer from "../../../containers/LayoutWithDataContainer";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierData } from "../../../Redux/Selectors/appConfigSelector";
import ExperiencesContent from "../list/containers/ExperiencesContent";
import {
  TitleExperiences,
  Container,
  ExperiencesWrapper,
} from "../list/styles/styles";
import useExperiences from "../../../hooks/useExperiences";
import { ScrollRestoration, useLocation } from "react-router-dom";
import { resetAvailableData } from "../redux/actions/availabilityActions";
import { setStartTime } from "../../ShopPage/redux/actions/shopActions";
import { getSpExperiencePromotion } from "../../../helpers/rentals";
import useWebsiteRegion from "../../../hooks/useWebsiteCountry";
import BannerRenderContainer from "../../../components/BannerComponent/BannerRenderContainer";
import { BANNER_PAGE } from "../list/helpers/constants";

const ExperiencesListPageScreen = ({ isIframe }) => {
  const { t } = useTranslation(["common", "experiences"]);
  const location = useLocation();
  const dispatch = useDispatch();
  const supplier = useSelector(getSupplierData);
  const { experiences, loading } = useExperiences();
  const { region } = useWebsiteRegion();
  const spExperiencePromotion = getSpExperiencePromotion(supplier?._id, region);

  useEffect(() => {
    dispatch(resetAvailableData());
    dispatch(setStartTime([]));
    registerSuperProperties({
      isIframe,
    });
    trackEvent(clickEvents.OPEN_PAGE, {
      page: "ExperiencesPage",
      supplier: supplier.name,
      supplierId: supplier._id,
    });
  }, []);

  return (
    <LayoutWithDataContainer isIframe={isIframe}>
      <Container>
        <ExperiencesWrapper>
          <BannerRenderContainer screen={BANNER_PAGE.experienceScreen} />
          <TitleExperiences level={3}>
            {t("experiences:experiences_title")}
          </TitleExperiences>
          <ExperiencesContent
            experiences={experiences}
            loading={loading}
            promotionExperience={spExperiencePromotion}
            location={location.search}
          />
        </ExperiencesWrapper>
      </Container>
      <ScrollRestoration />
    </LayoutWithDataContainer>
  );
};

export default ExperiencesListPageScreen;
