import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierData } from "../Redux/Selectors/appConfigSelector";
import {
  getAllSpaces,
  getAllSpacesIsFetching,
} from "../features/SpacesPage/redux/selectors/spacesSelectors";
import { getSpacesRequest } from "../features/SpacesPage/redux/actions/spacesActions";

const useSpaces = () => {
  const dispatch = useDispatch();
  const supplierData = useSelector(getSupplierData);
  const spaces = useSelector((state) =>
    getAllSpaces(state, { visibleOnWeb: true })
  );
  const spacesIsFetching = useSelector((state) =>
    getAllSpacesIsFetching(state)
  );
  useEffect(() => {
    if (supplierData?._id) {
      dispatch(
        getSpacesRequest({
          owner: supplierData?._id,
          published: true,
          active: true,
        })
      );
    }
  }, [JSON.stringify(supplierData)]);

  return { spaces, loading: spacesIsFetching };
};

export default useSpaces;
