import React from "react";
import PropTypes from "prop-types";
import { Container, Wrapper } from "./styles/FeaturedExperiences.styles";
import {
  Carousel,
  SlideWrapper,
} from "./styles/CarouselFeaturedExperiences.styles";
import { Link, useNavigate } from "react-router-dom";
import FeaturedExperienceCard from "./components/FeaturedExperienceCard";
import useBreakpoints from "../../../../hooks/useBreakpoints";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { getConvertedPrice } from "../../../ExperiencesPage/list/helpers/helpers";
import { navigateExperience } from "../../helpers/helpers";
import { Skeleton } from "antd";

const FeaturedExperiences = ({
  experiences,
  location,
  promotionExperience,
  loading,
}) => {
  const { isMobile } = useBreakpoints();
  const { t } = useTranslation(["home"]);
  const navigate = useNavigate();

  if (isMobile) {
    return (
      <>
        {loading ? (
          <Skeleton isLoading={loading} />
        ) : (
          <Carousel dots={true} draggable>
            {experiences.map((experience, index) => (
              <SlideWrapper key={index}>
                <Link
                  to={navigateExperience(
                    experience?._id,
                    experience?.title,
                    location
                  )}
                  key={experience?._id}
                >
                  <FeaturedExperienceCard
                    customPrice={getConvertedPrice(
                      experience._id,
                      experience?.convertedPrice?.adultPrice,
                      promotionExperience
                    )}
                    images={experience?.images}
                    title={experience?.title}
                    activity={experience?.activity}
                    location={experience?.location}
                    price={experience?.convertedPrice?.adultPrice}
                    duration={experience?.duration}
                    difficulty={experience?.difficulty}
                    currency={experience?.convertedCurrency}
                  />
                </Link>
              </SlideWrapper>
            ))}
          </Carousel>
        )}
      </>
    );
  }
  return (
    <>
      {loading ? (
        <Skeleton isLoading={loading} />
      ) : (
        <Wrapper>
          <Container columnsLength={experiences.length}>
            {experiences.map((experience) => (
              <Link
                to={navigateExperience(
                  experience?._id,
                  experience?.title,
                  location.search
                )}
                key={experience?._id}
              >
                <FeaturedExperienceCard
                  customPrice={getConvertedPrice(
                    experience._id,
                    experience?.convertedPrice?.adultPrice,
                    promotionExperience
                  )}
                  key={experience?._id}
                  images={experience?.images}
                  title={experience?.title}
                  activity={experience?.activity}
                  location={experience?.location}
                  price={experience?.convertedPrice?.adultPrice}
                  duration={experience?.duration}
                  difficulty={experience?.difficulty}
                  currency={experience?.convertedCurrency}
                />
              </Link>
            ))}
          </Container>
          <Button
            type="primary"
            shape="round"
            onClick={() => navigate("/experiences")}
          >
            {t("seeMoreExperiences")}
          </Button>
        </Wrapper>
      )}
    </>
  );
};

FeaturedExperiences.propTypes = {
  experiences: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      activity: PropTypes.arrayOf(PropTypes.string),
      location: PropTypes.string,
      price: PropTypes.shape({
        adultPrice: PropTypes.number,
      }),
      duration: PropTypes.shape({
        unit: PropTypes.string,
        value: PropTypes.number,
      }),
      difficulty: PropTypes.string,
      currency: PropTypes.string,
    })
  ),
};

export default FeaturedExperiences;
