import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Collapse from "antd/es/collapse";
import Text from "antd/es/typography/Text";
import Divider from "antd/es/divider";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  ProductListItem,
  FlexContainer,
  SizedText,
  Disclaimer,
  StyledCollapse,
  PaidAmount,
  ConvertedTotal,
} from "./SummaryPaymentDetail.styles";
import { isNull } from "lodash";
import { renderPrice } from "../../../helpers/tools";
import { TRANSACTION_TYPE } from "../../HomePage/helpers/constants";

const SummaryPaymentDetail = ({
  products,
  paymentAmount,
  currencySymbol,
  currency,
  subtotal,
  discount,
  total,
  percentage,
  amountPaid,
  convertedPaymentCurrency,
  convertedPaymentAmount,
  bookingType,
  ...restProps
}) => {
  const { t } = useTranslation(["shop", "result"]);

  return (
    <div {...restProps}>
      <StyledCollapse ghost>
        <Collapse.Panel
          header={
            <SizedText level={5} strong>
              {t("subTotal")}
            </SizedText>
          }
          extra={
            <SizedText strong>
              {currencySymbol} {renderPrice(subtotal, currency)}
            </SizedText>
          }
          key="1"
        >
          {products.map(({ title, quantity }, index) => (
            <ProductListItem key={index}>
              <Text ellipsis type="secondary">
                {bookingType === TRANSACTION_TYPE.RENTAL
                  ? t(title)
                  : t(`paymentRate.${title}`)}
              </Text>
              <Text type="secondary">x{quantity}</Text>
            </ProductListItem>
          ))}
        </Collapse.Panel>
      </StyledCollapse>

      {discount && (
        <FlexContainer>
          <SizedText level={5} strong>
            {t("subtract")}
          </SizedText>
          <PaidAmount>
            - {currencySymbol}
            {renderPrice(discount, currency)}
          </PaidAmount>
        </FlexContainer>
      )}
      {amountPaid > 0 && (
        <FlexContainer>
          <SizedText level={5} strong>
            {t("result:alreadyPaid")}
          </SizedText>
          <PaidAmount>
            - {currencySymbol} {renderPrice(amountPaid, currency)}
          </PaidAmount>
        </FlexContainer>
      )}
      <Divider />
      <FlexContainer>
        <SizedText level={5} strong>
          {t("total")}
        </SizedText>
        <div>
          <SizedText strong>
            {currencySymbol} {renderPrice(paymentAmount, currency)}
          </SizedText>{" "}
          {!!total && !!percentage && (
            <SizedText type="secondary">
              ({parseInt(percentage)}% de {currencySymbol}
              {renderPrice(total, currency)})
            </SizedText>
          )}
        </div>
      </FlexContainer>
      {!!percentage && (
        <Disclaimer>
          <InfoCircleOutlined
            type="secondary"
            style={{
              fontSize: "12px",
            }}
          />
          <Text type="secondary">
            {t("paymentPercentageDisclaimer", {
              percentage: parseInt(percentage),
            })}
          </Text>
        </Disclaimer>
      )}
      {!isNull(convertedPaymentCurrency) && !isNull(convertedPaymentAmount) && (
        <ConvertedTotal>
          <Text type="secondary">{t("guestCurrencyDisclaimer")}</Text>{" "}
          <SizedText strong>
            {convertedPaymentCurrency}{" "}
            {renderPrice(convertedPaymentAmount, currency)}
          </SizedText>
        </ConvertedTotal>
      )}
    </div>
  );
};

SummaryPaymentDetail.defaultProps = {
  amountPaid: 0,
  convertedPaymentCurrency: null,
  convertedPaymentAmount: null,
};

SummaryPaymentDetail.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
  paymentAmount: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  total: PropTypes.number,
  percentage: PropTypes.number,
  amountPaid: PropTypes.number,
  convertedPaymentCurrency: PropTypes.number,
  convertedPaymentAmount: PropTypes.string,
};

export default SummaryPaymentDetail;
