import Paragraph from "antd/es/typography/Paragraph";
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useIsMobile from "../../../../hooks/useIsMobile";
import { saveMainMemberInfo } from "../../redux/actions/MainMemberActions";
import { getPassengersByKey } from "../../redux/selectors/ShopSelectors";
import { findProvinceIdByName, removeSpaces } from "../../utils";
import FloatingSummary from "../FloatingSummary/FloatingSummary";
import GroupDetailsModal from "../GroupDetailModal/GroupDetailModal";
import MainMemberForm from "../MainMemberForm/MainMemberForm";
import BannerRenderContainer from "../../../../components/BannerComponent/BannerRenderContainer";
import { DateOfBirthContext } from "../../../../components/DateOfBirthSelector/context/DateOfBirthContext";
import { sanitizeDateOfBirth } from "../../../../tools/dateTools";
import { sanitizePhonePrefix } from "../../../../helpers/tools";
import { BANNER_PAGE } from "../../../ExperiencesPage/list/helpers/constants";

const Container = styled.div`
  padding-bottom: 200px;
`;

const ContentContainer = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const FormContainer = styled.div`
  flex: 1;
`;

const DetailCheckout = ({
  handleOnInfoSubmit,
  onContinue,
  pricingData,
  quoteSummary,
  isPricingFetching,
  selectedProducts,
  itemsLength,
  discounts,
  isButtonEnabled,
  onFormIsValid,
}) => {
  const { t } = useTranslation(["common", "shop"]);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState({
    visible: false,
    data: {
      passenger: null,
    },
  });
  const { dateOfBirth } = useContext(DateOfBirthContext);
  const passenger = useSelector(
    (state) => getPassengersByKey(state, showModal.data?.passenger),
    shallowEqual
  );

  const handleModalOnCancel = () => {
    setShowModal({
      visible: false,
      data: {
        passenger: null,
      },
    });
  };

  const saveMainMember = (val) => {
    const provinceId = findProvinceIdByName(
      val.nationality?.toLowerCase(),
      val.province
    );
    const phonePrefix =
      val?.phonePrefix && sanitizePhonePrefix(val?.phonePrefix);
    const data = {
      ...val,
      name: removeSpaces(val.name),
      surname: removeSpaces(val.surname),
      dateOfBirth: sanitizeDateOfBirth(dateOfBirth),
      province: provinceId,
      phonePrefix,
    };
    dispatch(saveMainMemberInfo(data));
  };

  return (
    <Container>
      <BannerRenderContainer screen={BANNER_PAGE.formScreen} />
      <ContentContainer>
        <Paragraph>{t("shop:checkout.description")}</Paragraph>
        <Body>
          <FormContainer>
            <MainMemberForm
              onFormIsValid={onFormIsValid}
              handleOnContinue={saveMainMember}
            />
          </FormContainer>
          {!isMobile && (
            <div
              style={{
                width: "40%",
                paddingLeft: 40,
                marginRight: 15,
              }}
            >
              <FloatingSummary
                visible={true}
                itemsLength={itemsLength}
                pricingData={pricingData}
                period={quoteSummary?.period}
                selectedProducts={selectedProducts}
                isPricingFetching={isPricingFetching}
                expandedDefault
                expandable={false}
                offset={{
                  offsetTop: 120,
                }}
                onActionClick={onContinue}
                buttonLabel={t("shop:continueAndPay")}
                isButtonEnabled={isButtonEnabled}
                discounts={discounts}
              />
            </div>
          )}
        </Body>
      </ContentContainer>
      <GroupDetailsModal
        visible={showModal.visible}
        passenger={passenger}
        handleOnSubmit={handleOnInfoSubmit}
        handleOnCancel={handleModalOnCancel}
      />
    </Container>
  );
};

export default DetailCheckout;
