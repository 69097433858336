import React from "react";
import { renderToString } from "react-dom/server";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import {
  GoogleMap,
  Marker,
  Circle,
  useLoadScript,
} from "@react-google-maps/api";
import environment from "../../config/environment";
import mapStyle from "./mapStyle";
import { ReactComponent as MapMarker } from "../../assets/mapMarker.svg";

const MapComponent = ({ coordinates, zoom, className, radius }) => {
  const theme = useTheme();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: environment.googleMaps.apiKey,
    id: "google-maps-script",
  });
  const lng = coordinates[0];
  const lat = coordinates[1];
  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(14);
  }, []);

  const sanitizePosition = (coordinates) => {
    const lng = coordinates[0];
    const lat = coordinates[1];
    return new window.google.maps.LatLng(Number(lat), Number(lng));
  };

  const renderMarkers = () => {
    const svgIcon = renderToString(<MapMarker />);
    return radius ? (
      <Circle
        key={"1"}
        center={sanitizePosition(coordinates)}
        radius={radius}
        options={{
          fillColor: theme.colors.primary,
          strokeColor: theme.colors.primary,
        }}
      />
    ) : (
      <Marker
        key={"1"}
        position={sanitizePosition(coordinates)}
        animation={window.google.maps.Animation.DROP}
        icon={{
          url: `data:image/svg+xml;base64,${window.btoa(svgIcon)}`,
          scaledSize: new window.google.maps.Size(50, 50),
        }}
      />
    );
  };

  return (
    isLoaded && (
      <GoogleMap
        zoom={zoom}
        center={new window.google.maps.LatLng(Number(lat), Number(lng))}
        onLoad={onLoad}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          zoomControl: true,
          streetViewControl: false,
          styles: mapStyle,
          gestureHandling: "cooperative",
        }}
        mapContainerClassName={className}
      >
        {renderMarkers()}
      </GoogleMap>
    )
  );
};

MapComponent.defaultProps = {
  radius: false,
};

MapComponent.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  zoom: PropTypes.number,
  className: PropTypes.string,
  radius: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default MapComponent;
