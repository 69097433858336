import { Form } from "antd";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  calculateItemsLength,
  normalizeProducts,
} from "../../../../helpers/tools";
import { getSearchValues } from "../../../../Redux/Selectors/searchSelectors";
import { getSupplierProfileData } from "../../../../Redux/Selectors/supplierSelectors";
import {
  assignVariantToPassenger,
  setSelectedProducts,
} from "../../redux/actions/shopActions";
import {
  getAssigmentsData,
  getAssignmentsLength,
  getPassengersData,
  getSummary,
  getItems,
  getSelectedProducts,
} from "../../redux/selectors/ShopSelectors";
import { ITEM_TYPE } from "../../constants";
import { isEmpty } from "lodash";

const AssignmentsContainer = ({ children }) => {
  const dispatch = useDispatch();
  const [assignFormController] = Form.useForm();
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const searchValues = useSelector(getSearchValues);
  const navigate = useNavigate();
  const quoteSummary = useSelector(getSummary);
  const passengersData = useSelector(getPassengersData);
  const assigments = useSelector(getAssigmentsData);
  const assignmentsLength = useSelector(getAssignmentsLength);
  const productsDetail = useSelector((state) =>
    getItems(state, normalizeProducts(quoteSummary.products))
  );
  const itemsLength = calculateItemsLength(quoteSummary?.products);
  const supplierData = useSelector(getSupplierProfileData);
  const sanitizedProducts = productsDetail.map((product, index) => {
    return {
      ...product,
      fieldKey: `${product._id}-${index}`,
    };
  });
  const selectedProducts = useSelector(getSelectedProducts, shallowEqual);

  const handleOnSubmitAssign = ({
    passenger,
    productId,
    bundleId = 0,
    fieldKey,
    variantId,
    type,
    ...values
  }) => {
    const variantKeys = Object.keys(values).filter((key) =>
      key.startsWith("variant-")
    );
    if (type === ITEM_TYPE.BUNDLE) {
      variantId = variantKeys.map((key) => {
        const variantProductId = key.split("-")[1];
        return {
          reference: values[key],
          type: ITEM_TYPE.VARIANT,
          bundleId,
          productId: variantProductId,
        };
      });
    } else {
      variantId = values[variantKeys[0]];
    }
    dispatch(
      assignVariantToPassenger({
        passenger,
        variantId,
        productId,
        bundleId,
        index: fieldKey,
        type,
      })
    );
  };

  useEffect(() => {
    if (assignmentsLength === itemsLength) {
      setButtonEnabled(true);
    }
  }, [assigments, itemsLength]);

  useEffect(() => {
    if (!isEmpty(sanitizedProducts)) {
      dispatch(setSelectedProducts(sanitizedProducts));
    }
  }, []);

  useEffect(() => {
    const handlePopState = () => {
      setIsModalVisible(true);
    };
    const handleBeforeUnload = (event) => {
      event.preventDefault();
    };

    const initialPath = window.location.pathname + window.location.search;
    window.history.pushState(null, "", initialPath);

    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (navigateBack) {
      window.history.go(-1);
    }
  }, [navigateBack]);

  const handleConfirm = () => {
    setIsModalVisible(false);
    setNavigateBack(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    const currentPath = window.location.pathname + window.location.search;
    window.history.pushState(null, "", currentPath);
  };

  const handleOnAssignProductsContinue = () => {
    navigate(`/shop/checkout${window.location.search}`);
  };
  const quoteData = {
    passengers: passengersData,
    quoteSummary,
    productsDetail: selectedProducts,
    assigments,
  };
  return children({
    quoteSummary,
    quoteData,
    handleOnSubmitAssign,
    assignFormController,
    handleOnAssignProductsContinue,
    itemsLength,
    isButtonEnabled,
    supplierData,
    selectedProducts,
    searchValues,
    handleCancel,
    handleConfirm,
    isModalVisible,
  });
};

export default AssignmentsContainer;
